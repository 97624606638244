<template>
  <v-layout class="layout">
    <nav-bar
      :key="countPoints"
      :tabs="tabs"
      :is-show-user-info="isShowUserInfo"
      :count="countPoints"
    />
    <div class="main-container w-100">
      <v-main :class="['main content', hasFilters && 'main-has-filters']">
        <router-view :key="route.fullPath" />
        <Footer v-if="showFooter" />
      </v-main>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'DefaultLayout',
}
</script>
<script setup>
import Footer from '@/components/AppFooter.vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import NavBar from '@/components/NavBar.vue'
import { useRoute } from 'vue-router'
import { ADMIN, MANAGER, MONITOR_CONTENT, USER, MONITOR, EXPERT } from '@/enums/roles'
import TaskIcon from '@/components/Icons/TaskIcon.vue'
import CompetitionsIcon from '@/components/Icons/CompetitionsIcon.vue'
import UsersIcon from '@/components/Icons/UsersIcon.vue'
import { api } from '@/api/Api'
import DiagnosticsIcon from '@/components/Icons/DiagnosticsIcon.vue'
import ProjectIcon from '@/components/Icons/ProjectIcon.vue'

const store = useStore()
const route = useRoute()

const hasFilters = computed(
  () =>
    route.name === 'programs-list' ||
    route.name === 'tasks-list' ||
    route.name === 'programs-view-data' ||
    route.name === 'usersList' ||
    route.name === 'diagnostics-list',
)
const showFooter = computed(() => !route.meta.hideFooter)
const isShowUserInfo = computed(
  () => route.name !== 'create-project-by-code-1c' && route.name !== 'task-by-id',
)
const roles = computed(() => store.getters['user/currentRoles'])

const countPoints = ref(0)

const tabsProgramms = [
  {
    sort: 1,
    title: 'Конкурсы',
    to: '/programs',
    component: CompetitionsIcon,
  },
  {
    sort: 2,
    title: 'Проекты',
    to: '/projects',
    component: ProjectIcon,
  },
]

const tabsTasks = [
  {
    sort: 3,
    title: 'Мои задачи',
    to: '/tasks',
    hasCount: true,
    component: TaskIcon,
  },
]

const tabsToAdmin = [
  {
    sort: 4,
    title: 'Пользователи и роли',
    to: '/users',
    component: UsersIcon,
  },
]

const tabsDefault = [
  {
    sort: 5,
    title: 'Диагностика ЭП',
    to: '/diagnostics',
    component: DiagnosticsIcon,
  },
]

const tabs = computed(() => {
  let tabs = []
  roles.value?.forEach((role) => {
    if (role === ADMIN) {
      tabs = [...tabs, ...tabsToAdmin]
    }

    if (role === USER) {
      tabs = [...tabs, ...tabsTasks, ...tabsProgramms]
    }

    if (role === MANAGER || role === EXPERT) {
      tabs = [...tabs, ...tabsProgramms, ...tabsTasks]
    }

    if (role === MONITOR || role === MONITOR_CONTENT) {
      tabs = [...tabs, ...tabsTasks]
    }

    tabs = [...tabs, ...tabsDefault]
  })

  return removeDuplicatesByKey(tabs, 'to')?.sort((a, b) => a.sort - b.sort)
})

const getPointsCount = async () => {
  try {
    await api
      .projects()
      .getActivePointsCount()
      .then((data) => {
        countPoints.value = data?.count
      })
  } catch (err) {
    console.log(err)
  }
}

function removeDuplicatesByKey(arr, key) {
  return arr.filter(
    (item, index, self) => index === self.findIndex((obj) => obj[key] === item[key]),
  )
}

onMounted(() => {
  store.dispatch('group/getGroupSystem')
  getPointsCount()
})
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  min-height: 100%;

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.layout {
  max-height: 100vh;
}

.main {
  height: auto;
  background: var(--light-background);
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.main-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;

  .main {
    overflow: auto;
    padding-top: 0;

    &.main-has-filters {
      margin-top: 64px;
    }
  }
}
</style>
